<template>
  <div class="special-ico">
    <Header titleurl="special" />
    <div class="content">
      <div class="success-wrap">
        <div class="wrap-w">
          <div class="m succeed-box">
            <div class="mc success-cont">
              <div class="success-lcol">
                <div class="success-top"><b class="icon el-icon-success"></b>
                  <h3 class="ftx-02">商品已成功加入购物车！</h3>
                </div>
                <div class="p-item">
                  <div class="p-img"><a href="//item.jd.com/100002404996.html" target="_blank"><img
                        src="//img10.360buyimg.com/cms/s60x60_jfs/t1/32555/34/535/410483/5c3db256Ebada2efc/6d602ae4618cb4e3.jpg"
                        clstag="pageclick|keycount|201601152|11"></a></div>
                  <div class="p-info">
                    <div class="p-name"><a href="//item.jd.com/100002404996.html" target="_blank"
                        clstag="pageclick|keycount|201601152|2"
                        title="安钛克（Antec）暗黑系-复仇者X（DA601） 中塔钢化玻璃侧透 配赠幻彩ARGB风扇 360水冷电脑游戏机箱">安钛克（Antec）暗黑系-复仇者X（DA601）
                        中塔钢化玻璃侧透 配赠幻彩ARGB风扇 360水冷电脑游戏机箱</a></div>
                    <div class="p-extra"><span class="txt" title="暗黑系列">颜色：暗黑系列</span><span class="txt"
                        title="复仇者X">尺码：复仇者X</span><span class="txt">/ 数量：1</span></div>
                  </div>
                  <div class="clearfix"></div>
                </div>
              </div>
              <div class="success-btns success-btns-new">
                <div class="success-ad"><a href="#none"></a></div>
                <div class="clearfix"></div>
                <div><a class="btn-tobback" href="//item.jd.com/100002404996.html" target="_blank"
                    clstag="pageclick|keycount|201601152|3">查看商品详情</a><a class="btn-addtocart"
                    href="//cart.jd.com/cart.action?r=0.2770142572676686" id="GotoShoppingCart"
                    clstag="pageclick|keycount|201601152|4"><b></b>去购物车结算</a></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="clearfix"></div>
    <Footer />
  </div>
</template>

<script>
import Header from '@/components/header.vue';
import Footer from '@/components/footer.vue';
import {
  productgetcustomdetail,
  productgetspecialproductlist,
} from '@/api/product';
export default {
  name: 'App',
  components: {
    Header,
    Footer,
  },
  data() {
    return {};
  },
  methods: {},
  watch: {},
  mounted() {},
  created() {
    this.id = this.$route.query.id;
    //sthis.onproductgetcustomdetail();
  },
};
</script>
<style  scoped >
body {
  background-color: #fff;
}
img {
  border: 0;
  vertical-align: middle;
}
.ftx-02,
.ftx02 {
  color: #71b247;
}
.clearfix {
  clear: both;
}
.content {
  width: 100%;
  margin: 0 auto;
  min-height: 700px;
  background-color: #fff;
}
.content .success-wrap {
  height: 145px;
  background: #f5f5f5;
  margin-bottom: 20px;
}
.content .success-wrap .wrap-w {
  width: 1200px;
  margin: 0 auto;
}
.content .success-wrap .succeed-box .success-cont {
  display: block;
  font-family: 'Microsoft YaHei';
}
.content .success-wrap .success-lcol {
  float: left;
}
.succeed-box .success-top {
  position: relative;
  padding-left: 35px;
  margin: 20px 0;
}
.succeed-box .success-top h3 {
  font-size: 18px;
  line-height: 25px;
  font-weight: 400;
}
.succeed-box .success-top .icon {
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  width: 25px;
  height: 25px;
  overflow: hidden;
  font-size: 25px;
  color: #71b247;
}
.succeed-box .p-img {
  float: left;
  width: 60px;
  margin-right: 10px;
  border: 1px solid #e8e8e8;
}
.succeed-box .p-info {
  float: left;
  width: 520px;
  margin: 0;
}
.succeed-box .p-name {
  width: 100%;
  line-height: 28px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 14px;
}
.succeed-box .p-name a {
  color: #333;
}
.succeed-box .p-extra {
  display: inline-block;
  display: block;
  color: #aaa;
}
.succeed-box .p-extra .txt {
  float: left;
  max-width: 135px;
  margin-right: 5px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.succeed-box .success-btns {
  float: right;
  *width: 348px;
  font-size: 0;
  overflow: hidden;
}
.succeed-box .success-ad {
  float: right;
  height: 70px;
  width: 106px;
  margin: 10px 0;
}
.succeed-box .btn-addtocart,
.succeed-box .btn-tobback,
.succeed-box .btn-viewcart {
  display: inline-block;
  *display: inline;
  *zoom: 1;
  height: 34px;
  line-height: 36px;
  font-size: 16px;
  vertical-align: middle;
}
.succeed-box .btn-tobback {
  padding: 0 30px;
  margin-right: 10px;
  *margin-right: 9px;
  background: #fff;
  color: #e2231a;
  border: 1px solid #fff;
}
.succeed-box .btn-addtocart {
  position: relative;
  width: 136px;
  padding-left: 30px;
  background: #e2231a;
  border: 1px solid #e2231a;
  color: #fff;
}
</style>
